import React from 'react'
import { isLoggedIn , isBrowser, setAccessToken } from '../../services/auth'
import { navigate } from 'gatsby'
import firebase from 'gatsby-plugin-firebase'

function AuthCallback() {
  if (isBrowser()) {
    const token = new URLSearchParams(window.location.search).get('token')
    const goto = new URLSearchParams(window.location.search).get('goto')

    console.log(token, goto)

    setAccessToken(token)

    if (isLoggedIn()) {
      firebase.analytics().logEvent("SIGN_IN_WITH_AUTH_PROVIDER_COMPLETE");
      navigate(goto || '/')
    } else {
      firebase.analytics().logEvent("SIGN_IN_WITH_AUTH_PROVIDER_FAILED");
      if (goto) { 
        navigate(`/sign-in?redirect=${goto}`) 
      } else {
        navigate(`/sign-in`) 
      } 
    }
  }
  
  return <div />
}

export default AuthCallback
