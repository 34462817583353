import React from 'react'
import AuthCallback from '../../components/AuthCallback'

function Login() {
  return (
    <AuthCallback />
  )
}

export default Login
